import { TagLabel, Tooltip } from '@chakra-ui/react';
import React from 'react';

import getStylesByColorScheme from 'theme/utils/getStylesByColorScheme';
import Tag from 'ui/shared/chakra/Tag';

export type StatusTagType = 'ok' | 'error' | 'pending';

export interface Props {
  colorScheme: string;
  text: string;
  errorText?: string | null;
  isLoading?: boolean;
}

const StatusTagV2 = ({ colorScheme, text, errorText, isLoading }: Props) => {
  const styles = getStylesByColorScheme(colorScheme);

  return (
    <Tooltip label={ errorText }>
      <Tag
        variant="primary"
        isLoading={ isLoading }
        display="flex"
        paddingX={ 2.5 }
        paddingY={ 2 }
        bg={ styles.bgTransparent }
        borderColor={ styles.borderColor }
        color="white"
        textTransform="capitalize"
      >
        <TagLabel display="block">{ text }</TagLabel>
      </Tag>
    </Tooltip>
  );
};

export default StatusTagV2;
