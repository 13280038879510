import React from 'react';

import type { Transaction, TransactionType } from 'types/api/transaction';

import Loader from '../Loader';
import StatusTagV2 from './StatusTagV2';

export interface Props {
  status: Transaction['status'];
  types?: Array<TransactionType>;
  errorText?: string | null;
  isLoading?: boolean;
}

const TYPES_ORDER: Array<TransactionType> = [
  'blob_transaction',
  'rootstock_remasc',
  'rootstock_bridge',
  'token_creation',
  'contract_creation',
  'token_transfer',
  'contract_call',
  'coin_transfer',
];

const TxStatus = ({ status, types, errorText, isLoading }: Props) => {

  if (status === undefined || types === undefined) {
    return null;
  }

  const typeToShow = types.sort((t1, t2) => TYPES_ORDER.indexOf(t1) - TYPES_ORDER.indexOf(t2))[0];
  const { label, colorScheme } = getTxTypes(typeToShow);
  const { text } = getTxStatusInfo(status);

  return (
    <>
      { status === null && <Loader mb={ 4 }/> }
      <StatusTagV2
        colorScheme={ colorScheme }
        text={ `${ label } ${ text }` }
        errorText={ errorText }
        isLoading={ isLoading }
      />
    </>
  );
};

const getTxStatusInfo = (status: Transaction['status']): {text: string } => {
  let text = 'Pending';

  switch (status) {
    case 'ok':
      text = 'Success';
      break;
    case 'error':
      text = 'Failed';
      break;
  }

  return { text };
};

const getTxTypes = (typeToShow: TransactionType): {label: string; colorScheme: string} => {
  let label = 'Transaction';
  let colorScheme = 'blue';

  switch (typeToShow) {
    case 'contract_call':
      label = 'Contract call';
      colorScheme = 'green';
      break;
    case 'blob_transaction':
      label = 'Blob txn';
      colorScheme = 'yellow';
      break;
    case 'contract_creation':
      label = 'Contract creation';
      colorScheme = 'blue';
      break;
    case 'token_transfer':
      label = 'Token transfer';
      colorScheme = 'orange';
      break;
    case 'token_creation':
      label = 'Token creation';
      colorScheme = 'orange';
      break;
    case 'coin_transfer':
      label = 'Coin transfer';
      colorScheme = 'orange';
      break;
    case 'rootstock_remasc':
      label = 'REMASC';
      colorScheme = 'blue';
      break;
    case 'rootstock_bridge':
      label = 'Bridge';
      colorScheme = 'blue';
      break;
  }

  return { label, colorScheme };
};

export default TxStatus;
