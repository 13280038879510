import type { FlexProps } from '@chakra-ui/react';
import { Text, Grid, Skeleton } from '@chakra-ui/react';
import React from 'react';

import type { Transaction } from 'types/api/transaction';

import chain from 'configs/app/chain';
import getValueWithUnit from 'lib/getValueWithUnit';
import useIsMobile from 'lib/hooks/useIsMobile';
import AddressFromTo from 'ui/shared/address/AddressFromTo';

type Props = {
  tx: Transaction;
  isLoading?: boolean;
};

const HEIGHT = '91px';

const LatestTxsInfo = ({ tx, isLoading }: Props) => {
  const isMobile = useIsMobile();
  const dataTo = tx.to ? tx.to : tx.created_contract;
  const commonStyles: FlexProps = {
    mt: 4,
    mb: 2,
    borderRadius: 12,
    _last: { mb: 0 },
  };
  const sx = {
    '& > span:nth-of-type(even)': {
      textAlign: 'right',
      color: 'white',
    },
  };

  const symbol = chain.currency.symbol;
  const TXS_DETAILS = [
    {
      content: 'Amount',
    },
    {
      content: tx.value ?
        `${ getValueWithUnit(tx.value).dp(5).toFormat() }  ${ symbol }` :
        '-',
    },
    {
      content: 'Transaction Fees',
    },
    {
      content: tx.fee.value ?
        `${ getValueWithUnit(tx.fee.value).dp(9).toFormat() }  ${ symbol }` :
        '-',
    },
  ];

  if (isLoading) {
    return (
      <Skeleton
        w="100%"
        h={ HEIGHT }
        isLoaded={ !isLoading }
        color="text_secondary"
        flexShrink={ 0 }
        { ...commonStyles }
      />
    );
  }

  return (
    <Grid
      w="100%"
      h={ HEIGHT }
      gridGap={ 2 }
      templateColumns="repeat(auto-fit, minmax(50%, 1fr))"
      p={ 3 }
      bg="background"
      sx={ sx }
      lineHeight="16.8px"
      { ...commonStyles }
    >
      <AddressFromTo
        w="100%"
        gridColumn="span 2"
        from={ tx.from }
        to={ dataTo }
        isLoading={ isLoading }
        mode={ isMobile ? 'compact' : 'long' }
        noIcon
        fontSize="sm"
        fontWeight={ 500 }
      />
      { TXS_DETAILS.map(({ content }, index) => (
        <Text
          key={ index }
          as="span"
          color="text_secondary"
          fontSize="sm"
          whiteSpace="pre"
        >
          { content }
        </Text>
      )) }
    </Grid>
  );
};

export default React.memo(LatestTxsInfo);
