import type { StyleProps } from '@chakra-ui/react';
import { Flex, Text, Skeleton, Box } from '@chakra-ui/react';
import React from 'react';

import type { Transaction } from 'types/api/transaction';

import useIsMobile from 'lib/hooks/useIsMobile';
import useTimeAgoIncrement from 'lib/hooks/useTimeAgoIncrement';
import TxEntity from 'ui/shared/entities/tx/TxEntity';
import TxStatus from 'ui/shared/statusTag/TxStatus';

import LatestTxsInfo from './LatestTxsInfo';

type Props = {
  tx: Transaction;
  isLoading?: boolean;
};

const commonStyles: StyleProps = {
  p: 2,
  borderRadius: 'sm',
};

const LatestTxsItem = ({ tx, isLoading }: Props) => {
  const isMobile = useIsMobile();
  const timeAgo = useTimeAgoIncrement(tx.timestamp || '0', true);

  const txMethod = isLoading ? (
    <Skeleton
      isLoaded={ !isLoading }
      w="62px"
      h="30px"
      { ...commonStyles }
    />
  ) : (
    <Box
      bg="whiteAlpha.100"
      fontSize="xs"
      fontWeight={ 500 }
      lineHeight="14px"
      { ...commonStyles }
    >
      { tx.method ?? 'Transfer' }
    </Box>
  );

  return (
    <Flex borderRadius={ 16 } flexDirection={{ base: 'column', md: 'row' }} mb={ 5 } overflow="hidden" _last={{ mb: 0 }}>
      <Flex
        maxW={{ base: '100%', md: '228px' }}
        w="100%"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        px={ 6 }
        py={{ base: 4, md: 0 }}
        bg="blue.600"
      >
        <TxStatus
          status={ tx.status }
          types={ tx.tx_types }
          errorText={ tx.status === 'error' ? tx.result : undefined }
          isLoading={ isLoading }
        />
        <Skeleton isLoaded={ !isLoading } mt={ 4 }>
          <Text fontWeight={ 500 }>
            Block{ ' ' }
            <Text as="span" fontWeight={ 700 }>
              #{ tx.block }
            </Text>
          </Text>
        </Skeleton>
        { tx.timestamp && (
          <Skeleton
            isLoaded={ !isLoading }
            color="text_secondary"
            fontWeight="400"
            fontSize="sm"
            flexShrink={ 0 }
            mt={ 1 }
          >
            <span>{ timeAgo }</span>
          </Skeleton>
        ) }
      </Flex>
      <Flex flexDirection="column" w="100%" px="30px" py={ 5 } bg="blue.700">
        <Flex justifyContent="space-between">
          <TxEntity
            isLoading={ isLoading }
            hash={ tx.hash }
            fontSize="sm"
            fontWeight={ 700 }
            noIcon
            truncation={ isMobile ? 'constant_long' : 'none' }
          />
          { txMethod }
        </Flex>
        <LatestTxsInfo tx={ tx } isLoading={ isLoading }/>
      </Flex>
    </Flex>
  );
};

export default React.memo(LatestTxsItem);
