import type { StyleProps } from '@chakra-ui/react';
import { keyframes } from '@emotion/react';
import React from 'react';

import IconSvg from './IconSvg';

interface Props extends StyleProps {
  size?: number;
}

const Loader = ({ size = 8, ...styles }: Props) => {
  return (
    <IconSvg
      boxSize={ size }
      name="loader"
      animation={ `1s linear infinite ${ spin() }` }
      { ...styles }
    />
  );
};

const spin = () =>
  keyframes({
    from: { transform: 'rotate(0deg)' },
    to: { transform: 'rotate(360deg)' },
  });

export default Loader;
